h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0.5em;
}


p {
	margin-bottom: 1rem;
}

a {
	color: inherit;

	&:active {
		color: inherit;
		opacity: 1;
	}
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.font-small.font-small {
	font-size: 0.8rem;
}

.font-medium.font-medium {
	font-size: 1rem;
}

.font-large.font-large {
	font-size: 1.2rem;
}

.bold {
	font-weight: bold !important;
}

.italic {
	font-style: italic;
}

.min-content-width {
	width: min-content;
}

.full-width {
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
}

.fill-available-width {
	width: -webkit-fill-available !important;
}

.max-content-width {
	width: max-content;
}

.full-height {
	height: 100%;
	max-height: 100%;
	box-sizing: border-box;
}

.half-height {
	height: 50%;
	max-height: 50%;
	box-sizing: border-box;
}

.centered-content {
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
}

.scroll-y {
	overflow-y: auto;
}

.scroll-hidden{
	overflow: hidden;
}

.grid-column {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
}

.flex-column.flex-column {
	display: flex;
	flex-direction: column;

	&.center {
		justify-content: center;
	}

	&.justify {
		justify-content: space-between;
	}

	&.even {
		justify-content: space-evenly;
	}

	&.around {
		justify-content: space-around;
	}

	&.top {
		align-items: flex-start;
	}

	&.middle {
		align-items: center;
	}

	&.bottom {
		align-items: end;
	}

	&.fill {
		>* {
			flex-grow: 1;
		}
	}

	&.gap-small {
		gap: var(--spacing-small);
	}

	&.gap-medium {
		gap: var(--spacing-medium);
	}

	&.gap-large {
		gap: var(--spacing-large);
	}

	&.wrap {
		flex-wrap: wrap;
	}

	>.flex-size-fill {
		height: 0;
	}
}

.flex-row.flex-row {
	display: flex;
	flex-direction: row;

	.container-50 {
		width: 50%;
	}

	&.center {
		justify-content: center;
	}

	&.align-center {
		align-items: center;
	}

	&.justify {
		justify-content: space-between;
	}

	&.even {
		justify-content: space-evenly;
	}

	&.around {
		justify-content: space-around;
	}

	&.end {
		justify-content: flex-end;
	}

	&.top {
		align-items: flex-start;
	}

	&.middle {
		align-items: center;
	}

	&.bottom {
		align-items: end;
	}

	&.fill {
		>* {
			flex-grow: 1;
		}
	}

	&.gap-small {
		gap: var(--spacing-small);
	}

	&.gap-medium {
		gap: var(--spacing-medium);
	}

	&.gap-large {
		gap: var(--spacing-large);
	}

	&.wrap {
		flex-wrap: wrap;
	}

	>.flex-size-fill {
		width: 0;
	}
}

@media (max-width: 1100px) {
	.content {
		flex: 100%;
	}
}

@media (max-width: 1720px) {
	.content {
		flex: 100%;
	}

	.grid-2xn {
		grid-template-columns: 50% 50% !important;
	}
}

.flex-size-fill {
	flex: 1 1 0;
}

.grid-2x2 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;

	&.gap-small {
		gap: var(--spacing-small);
	}

	&.gap-medium {
		gap: var(--spacing-medium);
	}

	&.gap-large {
		gap: var(--spacing-large);
	}
}

.grid-2xn {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-auto-flow: dense;
	min-height: 100vh;
	text-align: center;
	overflow-x: hidden;

	.container {
		height: auto;
	}
}

@media (max-width: 770px) {
	.grid-2xn {
		grid-template-columns: 1fr !important;
	}
}

.grid-3xn {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-flow: dense;
	text-align: center;

	.container {
		height: auto;
	}
}

@media (max-width: 1023px) {
	.grid-3xn {
		grid-template-columns: 1fr 1fr !important;
	}
}

.grid-2x1 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;

	&.gap-small {
		gap: var(--spacing-small);
	}

	&.gap-medium {
		gap: var(--spacing-medium);
	}

	&.gap-large {
		gap: var(--spacing-large);
	}
}

.grid-3x2 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr;

	&.gap-small {
		gap: var(--spacing-small);
	}

	&.gap-medium {
		gap: var(--spacing-medium);
	}

	&.gap-large {
		gap: var(--spacing-large);
	}

	// &.gap-large-dividers{
	// 	gap: var(--spacing-large);
	// 	position: relative;

	// 	&::before{
	// 		content: '';
	// 		display: block;
	// 		box-sizing: border-box;
	// 		border-style: solid;
	// 		border-color: mat.get-color-from-palette($foreground,divider);
	// 		border-width: 0 0 1px 0;
	// 		position: absolute;
	// 		height: 50%;
	// 		width: 100%;
	// 		pointer-events: none;
	// 	}

	// 	&::after{
	// 		content: '';
	// 		display: block;
	// 		box-sizing: border-box;
	// 		border-style: solid;
	// 		border-color: mat.get-color-from-palette($foreground,divider);
	// 		border-width: 0 1px 0 1px;
	// 		position: absolute;
	// 		left: calc(100%/3 - 3px);
	// 		right: calc(100%/3 - 3px);
	// 		top: 0;
	// 		height: 100%;
	// 		pointer-events: none;
	// 	}
	// }

}

.card {
	background-color: var(--panel-background);
	border-radius: 0.25rem;
	padding: var(--spacing-medium);
	box-shadow: 0px -2px 2px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.12) !important;
}

.section {
	margin: 0rem 2rem;

	&.start {
		padding-top: 1rem;
	}

	&.end {
		padding-bottom: 1rem;
	}
}

.container {
	padding: 1rem;
}

.container-graph {
	width: 100%;
	margin-top: -1rem;
}

.flex-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.blocks-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 1100px) {
	.flex-wrapper {
		display: flex;
		flex-direction: column;

		.content {
			width: 100%;
		}
	}

	.blocks-container {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}
}

// @media (max-width:600px) {
// 	.flex-row.flex-row{
// 		display: flex;
// 		flex-direction: column;

// 		.container-50{
// 			width:50%;
// 		}
// 		&.center{
// 			justify-content: center;
// 		}
// 		&.align-center{
// 			align-items: center;
// 		}

// 		&.justify{
// 			justify-content: space-between;
// 		}

// 		&.even{
// 			justify-content: space-evenly;
// 		}

// 		&.around{
// 			justify-content: space-around;
// 		}

// 		&.end{
// 			justify-content: flex-end;
// 		}

// 		&.top{
// 			align-items: flex-start;
// 		}

// 		&.middle{
// 			align-items: center;
// 		}

// 		&.bottom{
// 			align-items: end;
// 		}

// 		&.fill{
// 			>*{
// 				flex-grow: 1;
// 			}
// 		}

// 		&.gap-small{
// 			gap: var(--spacing-small);
// 		}

// 		&.gap-medium{
// 			gap: var(--spacing-medium);
// 		}

// 		&.gap-large{
// 			gap: var(--spacing-large);
// 		}

// 		&.wrap{
// 			flex-wrap: wrap;
// 		}

// 		>.flex-size-fill{
// 			width: 0;
// 		}
// 	}
// }

@media (max-width:450px) {
	.flex-percent {
		display: flex;
		flex-direction: column;

		.container-50 {
			width: auto !important;
		}
	}
}

.mt-20 {
	margin-top: 1rem
}

.mt-15 {
	margin-top: 0.8rem;
}

.flex-percent {
	display: flex;

	.container-33 {
		width: 33%;
	}

	.container-67 {
		width: 64%;
	}

	.container-50 {
		width: 50%;
	}

	.container-20 {
		width: 20%;
	}

	.container-80 {
		width: 78%
	}

	@media (max-width:1100px) {
		.container-33 {
			width: auto;
		}

		.container-67 {
			width: auto;
		}
	}
}

.button {
	padding: 1rem 2rem;
	align-items: center;
	font-weight: 600;
	font-size: inherit;
}

.image-label {
	width: 10rem;
	position: absolute;
	margin-bottom: 2rem;
	left: 10%;
	transform: translateX(-50%);
	z-index: 2;
}

.plot {
	svg {
		left: 0px;
	}
}

.spinner-container {
	width: --webkit-fill-available;
	padding-block: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mat-pickers {
	margin: 0.5rem;

	&.medium {
		mat-select {
			width: 18ch;
		}

		width: 12%;
		height: 20px;
		margin: 10px;
	}

	&.small {
		mat-select {
			width: 11ch;
		}

		width: 8%;
		height: 20px;
		margin: 10px;
	}

}

.not-found-svg {
	width: 80px !important;
	height: 80px !important;
	padding: 20px !important;
}

.popup {
	border-radius: 2rem !important;
	font-weight: 400;
	background-color: var(--popup-background) !important;
	padding: var(--spacing-medium);
	display: flex;
	color: var(--popup-color);
	flex-direction: column;
	align-items: stretch;
	gap: var(--spacing-medium);
}

.action-button {
	display: inline-flex !important;
	align-items: center !important;
	justify-content: center !important;
	gap: 0.5rem !important;
	border: none !important;
	margin: 0.5rem !important;
	border-radius: 0.5rem !important;
	padding: 0.5rem !important;
	color: var(--fontColor);
	background: var(--action-button) !important;
}

.mat-icon-svg {
	margin-left: 1rem !important;
	color: var(--fontColor);
}

.mat-mdc-card {
	background-color: var(--panel-background) !important;
	box-shadow: 0px -2px 2px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.12) !important;
}

.highcharts-axis-title {
	fill: var(--highcharts-labels) !important;
	color: var(--highcharts-labels) !important;
}

.highcharts-axis-labels,
.highcharts-yaxis-labels {
	fill: var(--highcharts-labels) !important;
}

.highcharts-yaxis-labels text {
	fill: var(--highcharts-labels) !important;
	cursor: default;
}

.highcharts-xaxis-labels text {
	fill: var(--highcharts-labels) !important;
}

.highcharts-legend text {
	fill: var(--highcharts-labels) !important;
}

.mat-button-toggle-label-content {
	padding: 0px !important;
}

.table-container {
	display: flex;
	align-content: stretch;

	.table {
		flex: 1;
		width: 100%;

		&.small {
			padding: 0.3rem;
		}

		&.normal {
			padding: 1rem;
		}

		&.horizontal {
			&.start {
				margin-right: 1rem;
			}

			&.middle {
				margin-inline: 1rem;
			}

			&.end {
				margin-left: 1rem;
			}
		}

		&.vertical {
			&.start {
				margin-bottom: 1rem;
			}

			&.middle {
				margin-block: 1rem;
			}

			&.end {
				margin-top: 1rem;
			}

			margin-left: 0.7rem;
		}

		.table-title {
			font-size: 1rem;
			margin-left: 1.5rem;
			padding: 0.5rem;
		}

		.row-small {
			min-height: 16px !important;
		}
	}
}

.payload-timeline-container{
    position: absolute ;
    height: 3.5rem ;
    display: flex ;
    width: 100%;
    z-index: 2;
    justify-content: space-between;

    .payload-header{
        margin: 1rem;

        .quantity-background{
            background-color: var(--charts-background);
        }
    }
}
